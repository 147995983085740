// Tapestry rendering functions
import { colors } from "../styles/colors";
export const MIN_FONT_SIZE = 24;
export const MAX_FONT_SIZE = 120;
const textAnchor = "middle";
const svgNS = "http://www.w3.org/2000/svg";
const isSafari =
  navigator.userAgent &&
  /^((?!chrome|android).)*safari/i.test(navigator.userAgent) &&
  navigator.userAgent.indexOf("CriOS") === -1 &&
  navigator.userAgent.indexOf("FxiOS") === -1;
const SAFARI_SCALAR = 0.65;
const TEXTPATH_SHRINK = 0.94;
const FONT_SIZE_SHRINK = 0.93;

function scaleToRange(num, inMin, inMax, outMin, outMax) {
  return ((num - inMin) * (outMax - outMin)) / (inMax - inMin) + outMin;
}

function getTextDimensions(textContent, fontSize) {
  const dummySVG = document.createElementNS(svgNS, "svg");
  const dummySVGText = document.createElementNS(svgNS, "text");
  dummySVGText.setAttribute("font-family", "'Hand1-Regular', Arial, Helvetica, sans-serif");
  dummySVGText.setAttribute("font-size", fontSize + "px");
  dummySVGText.textContent = textContent;
  dummySVG.appendChild(dummySVGText);
  document.body.appendChild(dummySVG);
  const measurement = {
    // hacky fix for Safari SVG render sizing discrepancies
    width: dummySVGText.getBBox().width * (isSafari ? SAFARI_SCALAR : 1),
    height: dummySVGText.getBBox().height,
  };
  document.body.removeChild(dummySVG);
  return measurement;
}

export function createText({
  textContent,
  x,
  y,
  fontSize,
  maxWidth,
  maxHeight,
  color,
  isKeySentence,
  isTitle,
  eventID,
}) {
  const texts = [];
  const lineHeightScalar = scaleToRange(fontSize, MAX_FONT_SIZE * 0.1, MAX_FONT_SIZE * 0.375, 1, 0.8);
  const words = textContent
    .replace(/\r?\n|\r/g, " ")
    .split(" ")
    .map((t) => {
      const bbox = getTextDimensions(t, fontSize);
      return { text: t.trim(), boundingBox: bbox };
    });
  const space = getTextDimensions(" ", fontSize).width;
  const lines = [
    {
      width: words[0].boundingBox.width,
      height: words[0].boundingBox.height * lineHeightScalar,
      words: [words[0]],
    },
  ];
  for (let i = 1; i < words.length; i++) {
    const line = lines[lines.length - 1];
    if (line.width + space + words[i].boundingBox.width > (maxWidth || Infinity)) {
      lines.push({
        width: words[i].boundingBox.width,
        height: words[i].boundingBox.height * lineHeightScalar,
        words: [words[i]],
      });
    } else {
      line.width += space + words[i].boundingBox.width;
      if (words[i].boundingBox.height > line.height) {
        line.height = words[i].boundingBox.height * lineHeightScalar;
      }
      line.words.push(words[i]);
    }
  }
  const totalHeight = lines.reduce((acc, curr) => acc + curr.height, 0);
  if (maxHeight && totalHeight > maxHeight) {
    return null;
  }
  let totalWidth = 0;
  // let yPos = y - totalHeight / 2
  lines.forEach((line) => {
    if (line.width > totalWidth) {
      totalWidth = line.width;
    }
    // yPos += line.height / 2
    const textData = {
      text: line.words.map((w) => w.text).join(" "),
      fulltext: textContent,
      width: line.width,
      height: line.height,
      color: color || colors.black,
    };
    if (isTitle) {
      textData.dominantBaseline = "middle";
    }
    texts.push(textData);
    // yPos += line.height / 2
  });
  const textObject = {
    position: { x, y },
    lines: lines.length,
    fontSize,
    text: textContent,
    texts,
    renderStyle: {
      width: totalWidth,
      height: totalHeight,
    },
    group: null,
    color: color || colors.black,
    isKeySentence,
    eventID,
  };
  textObject.texts.forEach((t) => {
    t.renderStyle = textObject.renderStyle;
  });
  return textObject;
}

export function createTextpath({
  id,
  container,
  textContent,
  fontSize,
  color,
  className,
  textpathLength,
  lines = 1,
  defaultOffset = 0,
  lineHeightScalar = 1,
  editable = false,
  eventID,
}) {
  const groupContainer = document.createElementNS(svgNS, "g");
  if (container) {
    container.appendChild(groupContainer);
  }
  if (eventID) {
    groupContainer.id = "phrase-" + eventID;
    groupContainer.setAttribute("fulltext", textContent);
    groupContainer.addEventListener("click", () =>
      window.dispatchEvent(
        new CustomEvent("selectText", {
          detail: { text: textContent },
        })
      )
    );
  }
  const wordsPerLine = [];
  let wordIndex = 0;
  const words = textContent.split(" ");

  // make sure none of the words are individually longer than the available textpath length
  let safeFontSize = fontSize;
  const longestWord = Math.max(...words.map((word) => getTextDimensions(word + " ", safeFontSize).width));
  if (longestWord > textpathLength * TEXTPATH_SHRINK) {
    safeFontSize *= (textpathLength * TEXTPATH_SHRINK) / longestWord;
  }

  for (let i = 0; i < lines; i++) {
    let subtext = "";
    if (lines === 1 || !textpathLength) {
      subtext = textContent;
    } else {
      let lineLength = 0;
      const space = wordIndex === words.length - 1 ? "" : " ";
      let nextWordLength = getTextDimensions(words[wordIndex] + space, safeFontSize).width;
      let midwayText;
      while (wordIndex < words.length && lineLength + nextWordLength <= textpathLength * TEXTPATH_SHRINK) {
        subtext += words[wordIndex] + space;
        lineLength += nextWordLength;
        // save line state when past halfway, incase we need to let the next line borrow some words
        if (lineLength > textpathLength * 0.5 && !midwayText) {
          midwayText = {
            wordIndex,
            subtext,
          };
        }
        wordIndex++;
        nextWordLength = getTextDimensions(
          words[wordIndex] + (wordIndex === words.length - 1 ? "" : " "),
          safeFontSize
        ).width;
      }
      // if we have a one-word dangler that will go on the next line, borrow some words from this line
      if (wordIndex === words.length - 1 && midwayText && words.length > 3) {
        subtext = midwayText.subtext;
        wordIndex = midwayText.wordIndex + 1;
      }
      subtext = subtext.trim();
      wordsPerLine[i] = i === 0 ? wordIndex : wordIndex - wordsPerLine[i - 1];
    }
    if (container && id) {
      const text = document.createElementNS(svgNS, "text");
      if (eventID) {
        text.addEventListener("click", () =>
          window.dispatchEvent(
            new CustomEvent("showPhraseOptions", {
              detail: { eventID },
            })
          )
        );
      }
      text.setAttribute("font-size", safeFontSize * FONT_SIZE_SHRINK + "px");
      text.setAttribute("font-family", "Hand1-Regular");
      text.setAttribute("text-anchor", textAnchor);
      const yOffset = safeFontSize * lineHeightScalar * i + defaultOffset;
      if (yOffset) {
        text.setAttribute("transform", `translate(0, ${yOffset})`);
      }
      if (editable) {
        text.setAttribute("class", "editable");
      }
      groupContainer.setAttribute(
        "class",
        (editable ? "editable-textpath " : "") + (className ? className + "-container" : "")
      );
      const textpath = document.createElementNS(svgNS, "textPath");
      if (color) {
        textpath.setAttribute("fill", color);
      }
      if (className) {
        textpath.setAttribute("class", className);
      }
      textpath.setAttribute("href", "#" + id);
      if (textAnchor === "middle") {
        textpath.setAttribute("startOffset", "50%");
      }
      textpath.setAttribute("fulltext", textContent);
      // if splitting two-word phrases across two lines and one of the words is too big, don't render anything
      if (words.length === 2 && wordsPerLine.length && wordsPerLine[0] === 1 && !subtext) {
        container.removeChild(groupContainer);
        return false;
      }
      textpath.textContent = subtext;
      text.appendChild(textpath);
      groupContainer.appendChild(text);
    }
  }
  return !(lines > 1 && wordIndex < words.length);
}

export function createAggregateTextpath({
  id,
  container,
  textContent,
  fontSize,
  color,
  className,
  textpathLength,
  lines = 1,
  editable = false,
  eventID,
}) {
  const groupContainer = document.createElementNS(svgNS, "g");
  if (container) {
    container.appendChild(groupContainer);
  }
  if (eventID) {
    groupContainer.id = "phrase-" + eventID;
    groupContainer.setAttribute("fulltext", textContent);
  }
  const wordsPerLine = [];
  let wordIndex = 0;
  const words = textContent.split(" ");

  // make sure none of the words are individually longer than the shortest available textpath length
  const shortestTextpathLength = Math.min(...textpathLength);
  let safeFontSize = fontSize;
  const longestWord = Math.max(...words.map((word) => getTextDimensions(word + " ", safeFontSize).width));
  if (longestWord > shortestTextpathLength * TEXTPATH_SHRINK) {
    safeFontSize *= (shortestTextpathLength * TEXTPATH_SHRINK) / longestWord;
  }

  for (let i = 0; i < lines; i++) {
    let subtext = "";
    if (lines === 1 || !textpathLength) {
      subtext = textContent;
    } else {
      let lineLength = 0;
      const space = wordIndex === words.length - 1 ? "" : " ";
      let nextWordLength = getTextDimensions(words[wordIndex] + space, safeFontSize).width;
      let midwayText;
      while (wordIndex < words.length && lineLength + nextWordLength <= textpathLength[i] * TEXTPATH_SHRINK) {
        subtext += words[wordIndex] + space;
        lineLength += nextWordLength;
        // save line state when past halfway, incase we need to let the next line borrow some words
        if (lineLength > textpathLength[i] * 0.5 && !midwayText) {
          midwayText = {
            wordIndex,
            subtext,
          };
        }
        wordIndex++;
        nextWordLength = getTextDimensions(
          words[wordIndex] + (wordIndex === words.length - 1 ? "" : " "),
          safeFontSize
        ).width;
      }
      // if we have a one-word dangler that will go on the next line, borrow some words from this line
      if (wordIndex === words.length - 1 && midwayText && words.length > 3) {
        subtext = midwayText.subtext;
        wordIndex = midwayText.wordIndex + 1;
      }
      subtext = subtext.trim();
      wordsPerLine[i] = i === 0 ? wordIndex : wordIndex - wordsPerLine[i - 1];
    }
    if (container && id) {
      const text = document.createElementNS(svgNS, "text");
      if (eventID) {
        text.addEventListener("click", () =>
          window.dispatchEvent(
            new CustomEvent("showPhraseOptions", {
              detail: { eventID },
            })
          )
        );
      }
      text.setAttribute("font-size", safeFontSize * FONT_SIZE_SHRINK + "px");
      text.setAttribute("font-family", "Hand1-Regular");
      text.setAttribute("text-anchor", textAnchor);
      if (editable) {
        text.setAttribute("class", "editable");
      }
      groupContainer.setAttribute(
        "class",
        (editable ? "editable-textpath " : "") + (className ? className + "-container" : "")
      );
      const textpath = document.createElementNS(svgNS, "textPath");
      if (color) {
        textpath.setAttribute("fill", color);
      }
      if (className) {
        textpath.setAttribute("class", className);
      }
      textpath.setAttribute("href", `#${id}-${i}`);
      if (textAnchor === "middle") {
        textpath.setAttribute("startOffset", "50%");
      }
      textpath.setAttribute("fulltext", textContent);
      // if splitting two-word phrases across two lines and one of the words is too big, don't render anything
      if (words.length === 2 && wordsPerLine.length && wordsPerLine[0] === 1 && !subtext) {
        if (container) {
          container.removeChild(groupContainer);
        }
        return false;
      }
      textpath.textContent = subtext;
      text.appendChild(textpath);
      groupContainer.appendChild(text);
    }
  }
  return !(lines > 1 && wordIndex < words.length);
}
