import { useState, useEffect, useContext, useCallback, useMemo } from "react";
import AppBackService from "../services/appback";
import { RootContext } from "../context/root-provider";
import classNames from "classnames";
import { Box, Skeleton, Typography, DialogContentText, Button } from "@mui/material";
import ErrorIcon from "@mui/icons-material/Error";
import { toTitleCase } from "../services/utilities";
import { colors } from "../styles/colors";

const imageStyles = {
  width: "200px",
  marginRight: "10px",
  marginBottom: "10px",
  boxSizing: "border-box",
  cursor: "pointer",
  position: "relative",
  "& img": {
    width: "200px",
    display: "block",
  },
  "&:hover": {
    opacity: 0.7,
  },
};
const styles = {
  thumbnail: {
    marginRight: "20px",
    marginBottom: "10px",
    marginTop: "10px",
    display: "inline-block",
  },
  imageSelect: {
    marginTop: "10px",
    display: "flex",
    flexWrap: "wrap",
  },
  image: imageStyles,
  selectedImage: {
    ...imageStyles,
    "& img": {
      width: "200px",
      display: "block",
      outline: `4px solid ${colors.secondaryGreen}`,
    },
  },
  heading: {
    fontWeight: "bold",
    color: colors.lightBlack,
    marginTop: "10px",
  },
  layoutTitle: {
    position: "absolute",
    textAlign: "center",
    top: "8px",
    fontWeight: "bold",
    padding: "4px 8px",
    borderRadius: "4px",
    backgroundColor: colors.white,
    boxShadow: "0 2px 2px rgba(0, 0, 0, 0.2)",
    left: "50%",
    transform: "translateX(-50%)",
  },
  autoSelectButton: {
    margin: "10px 0",
    textTransform: "none",
    "&.selected": {
      color: colors.green,
      borderWidth: "2px",
      borderColor: colors.green,
      fontWeight: "bold",
    },
  },
};

const defaultLayout = "605edbebcee46a5c13d585c7";

export default function useSelectLayout(doc, createTapRef) {
  const [error, setError] = useState(false);
  const [layouts, setLayouts] = useState();
  const [selectedLayout, setSelectedLayout] = useState(null);
  const [selectedBackground, setSelectedBackground] = useState(null);

  const { userInfo, meetingsSynced, loggingIn } = useContext(RootContext);

  // fetch data
  useEffect(() => {
    async function fetchData() {
      const data = await AppBackService.getLayouts();
      if (!data || data.error || !data.layouts?.length) {
        setError(true);
      } else {
        const layoutsObj = {};
        for (const layout of data.layouts) {
          if (layout.active) {
            const defaultBackground = layout.backgrounds.find((bg) => bg.default) || layout.backgrounds[0];
            layout.backgrounds.sort((a, b) => (defaultBackground === a.id ? -1 : defaultBackground === b.id ? 1 : 0));
            layoutsObj[layout.id] = layout;
          }
        }
        setLayouts(layoutsObj);
      }
    }
    if (userInfo?.username && meetingsSynced && !loggingIn) {
      fetchData();
    }
  }, [loggingIn, meetingsSynced, userInfo]);

  // assign a default background when a layout is selected
  useEffect(() => {
    if (layouts && selectedLayout) {
      const bgs = layouts[selectedLayout].backgrounds;
      setSelectedBackground(bgs.length === 1 ? bgs[0]?.id : bgs.find((bg) => bg.default)?.id || bgs[0]?.id);
    }
  }, [layouts, selectedLayout]);

  const selectLayout = useCallback((layoutId) => {
    setSelectedLayout(layoutId);
  }, []);

  const selectBackground = useCallback((backgroundId) => {
    setSelectedBackground(backgroundId);
  }, []);

  const autoSelectLayout = useCallback(() => {
    setSelectedLayout(null);
    setSelectedBackground(null);
    if (createTapRef.current) {
      createTapRef.current();
    }
  }, [createTapRef]);

  const imagesSkeleton = useMemo(
    () =>
      Array.from(new Array(3)).map((_d, i) => (
        <Skeleton key={i} variant="rectangular" width={190} height={120} sx={styles.thumbnail} />
      )),
    []
  );

  const layoutSelector = useMemo(() => {
    return error ? (
      <>
        <ErrorIcon />
        <DialogContentText>Error loading layouts, please try again later.</DialogContentText>
      </>
    ) : (
      <>
        {doc && <DialogContentText sx={{ ...styles.heading, marginTop: 0 }}>Document: {doc.title}</DialogContentText>}
        <DialogContentText sx={styles.heading}>Select a Layout:</DialogContentText>
        <Button
          variant="outlined"
          className={classNames({ selected: !selectedLayout && !selectedBackground })}
          sx={styles.autoSelectButton}
          onClick={autoSelectLayout}>
          Auto-Select Layout
        </Button>
        {layouts ? (
          <Box sx={styles.imageSelect}>
            {Object.values(layouts)
              .sort((a, b) => {
                const layout = Object.values(layouts).find((layout) => layout.default)?.id || defaultLayout;
                return layout === a.id ? -1 : layout === b.id ? 1 : 0;
              })
              .filter((layout) => layout.backgrounds.length)
              .map((layout) => {
                const defaultBackground = layout.backgrounds.find((bg) => bg.default) || layout.backgrounds[0];
                return (
                  <Box
                    key={layout.id}
                    sx={selectedLayout === layout.id ? styles.selectedImage : styles.image}
                    onClick={() => selectLayout(layout.id)}>
                    <img src={defaultBackground.imageUrl} alt={defaultBackground.description} draggable="false" />
                    <Typography sx={styles.layoutTitle}>{toTitleCase(layout.name)}</Typography>
                  </Box>
                );
              })}
          </Box>
        ) : (
          imagesSkeleton
        )}
        {selectedLayout && layouts[selectedLayout]?.backgrounds?.length > 1 && (
          <>
            <DialogContentText sx={styles.heading}>Select a Background:</DialogContentText>
            <Box sx={styles.imageSelect}>
              {layouts[selectedLayout]?.backgrounds.map((bg) => (
                <Box
                  key={bg.id}
                  sx={selectedBackground === bg.id ? styles.selectedImage : styles.image}
                  onClick={() => selectBackground(bg.id)}>
                  <img src={bg.imageUrl} alt={bg.description} draggable="false" />
                </Box>
              ))}
            </Box>
          </>
        )}
      </>
    );
  }, [
    autoSelectLayout,
    doc,
    error,
    imagesSkeleton,
    layouts,
    selectBackground,
    selectLayout,
    selectedBackground,
    selectedLayout,
  ]);

  return { error, selectedLayout, selectedBackground, layoutSelector };
}
